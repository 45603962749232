<script setup lang="ts">
import { type MyPageSurvey } from '../../type';

const props = defineProps<{
  survey: MyPageSurvey;
}>();

const classStatusCd = computed<string>(() => {
  let result = '';
  if (props.survey.refundStatusCd === 'C') {
    result = 'REFUND_COMPLETE';
  } else if (props.survey.refundStatusCd === 'W') {
    result = 'REFUND_WAIT';
  } else if (props.survey.studyBreakYn === 'Y') {
    result = 'STUDY_BREAK_ING';
  } else if (props.survey.studyStatusCd === 'E') {
    result = 'STUDY_END';
  } else if (props.survey.studyStatusCd === 'I') {
    result = 'STUDY_ING';
  } else if (props.survey.studyStatusCd === 'W') {
    result = 'STUDY_WAIT';
  }
  return result;
});

const classStatusNm = computed<string>(() => {
  let result = '';
  if (props.survey.refundStatusCd === 'C') {
    result = '환불완료';
  } else if (props.survey.refundStatusCd === 'W') {
    result = '환불대기';
  } else if (props.survey.studyBreakYn === 'Y') {
    result = '휴강중';
  } else if (props.survey.studyStatusCd === 'E') {
    result = '수강종료';
  } else if (props.survey.studyStatusCd === 'I') {
    result = '수강중';
  } else if (props.survey.studyStatusCd === 'W') {
    result = '수강대기';
  }
  return result;
});

const classStatusStyle = computed(() => {
  let result;
  if (props.survey.refundStatusCd === 'C') {
    result = { background: '#e4515a', color: '#ffffff' };
  } else if (props.survey.refundStatusCd === 'W') {
    result = { background: '#e4515a', color: '#ffffff' };
  } else if (props.survey.studyBreakYn === 'Y') {
    result = { background: '#f2f2f2', color: '#888' };
  } else if (props.survey.studyStatusCd === 'E') {
    result = { background: '#aaaaaa', color: '#ffffff' };
  } else if (props.survey.studyStatusCd === 'I') {
    result = { background: '#000', color: '#ffffff' };
  } else if (props.survey.studyStatusCd === 'W') {
    result = { background: '#aaaaaa', color: '#ffffff' };
  }
  return result;
});
</script>
<template>
  <Tag class="root" :value="classStatusNm" :style="classStatusStyle"></Tag>
</template>
<style lang="scss" scoped>
.root {
  @media screen and (max-width: $md) {
    :deep(.p-tag-value) {
      /* line-height: 12px; */
    }
  }
}
</style>
