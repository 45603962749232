<script setup lang="ts">
import Utility from '~/services/Utility';
import { type StudyCertificate, useMyPageStore } from '~/services/mypage';

import { type PaginationOptions } from '~/types/global';

import StudyCertificateDialog from './StudyCertificateDialog.vue';

const INIT_PARAMS = (): PaginationOptions => ({
  orderColumn: '',
  orderType: '',
  pageNo: 1,
  pageSize: 5,
});

const store = useMyPageStore();
const params = reactive<PaginationOptions>(INIT_PARAMS());
const selected = ref<StudyCertificate | null>(null);
const handleDialog = computed<boolean>({
  get() {
    return !!selected.value;
  },
  set(v) {
    if (!v) selected.value = null;
  },
});

const fireFetchList = (append: boolean = false) => {
  if (store.myStudyCertificates.paging) {
    store.myStudyCertificates.paging.hasMore = false;
  }
  store.fetchMyStudyCertificates(params, append);
};

const onInfinite = () => {
  if (store.myStudyCertificates.paging?.hasMore) {
    params.pageNo++;
    fireFetchList(true);
  }
};

await fireFetchList();
</script>

<template>
  <div class="wb-mypage-lecture">
    <div class="wb-mypage-lecture__head">
      <div
        class="wb-mypage-lecture__head-text"
        @click="
          $alert(
            '수료증은 온라인 유료 클래스 상품에 한하여 전체 수강률 70% 이상을 달성하시면 자동으로 발급됩니다. 수료증에는 성명, 클래스명, 수강기간, 수강금액을 포함하며, 임의로 변경하실 수 없습니다.',
          )
        ">
        <i class="pi pi-info-circle"></i>
        수료증발급 안내
      </div>
    </div>
    <div v-if="store.myStudyCertificates.items.length === 0" class="wb-mypage-none">
      <div class="wb-mypage-none__text">
        <p>수료하신 클래스가 없습니다.</p>
      </div>
    </div>
    <div v-else class="wb-mypage-lecture-list">
      <NuxtLink
        class="wb-mypage-lecture-item"
        v-for="item in store.myStudyCertificates.items"
        @click="selected = item"
        style="cursor: pointer">
        <div class="wb-mypage-lecture-item__thumb">
          <Image :src="Utility.getOptimized(item.displayThumbnailUrl, 'PRODUCT_THUMBNAIL')" alt="" />
        </div>
        <div class="wb-mypage-lecture-item__title">
          <p>{{ item.displayName }}</p>
        </div>
        <div class="wb-mypage-lecture-item__button">
          <Button label="수료증" size="small" severity="secondary" outlined />
        </div>
      </NuxtLink>
    </div>
    <Observer v-if="store.myStudyCertificates.paging?.hasMore" @intersect="onInfinite" />
  </div>
  <StudyCertificateDialog v-if="handleDialog" v-model:visible="handleDialog" :item="selected as StudyCertificate" />
</template>

<style scoped></style>
