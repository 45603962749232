<script setup lang="ts">
import {
  type UserSurveyQuestionAnswerResponseDTO,
  type UserSurveyQuestionDTO,
  type UserSurveyQuestionSelectionDTO,
} from '../../type';
import SurveyAreaTitle from './components/SurveyAreaTitle.vue';

const props = defineProps<{
  question: UserSurveyQuestionDTO;
  editable: boolean;
  onUpdateAnswer: (value: UserSurveyQuestionAnswerResponseDTO[]) => void;
}>();

const prefilledAnswer = computed(() => {
  const selections = props.question.answers.map((item) => item.selection);
  return props.question.isMultipleAnswer ? selections : selections[0];
});

const selectedAnswer = ref<UserSurveyQuestionSelectionDTO[] | UserSurveyQuestionSelectionDTO>(prefilledAnswer.value);

const answerCountText = computed(() => {
  if (props.question.minAnswerCount === props.question.maxAnswerCount) {
    return props.question.minAnswerCount;
  }
  return `${props.question.minAnswerCount} - ${props.question.maxAnswerCount}`;
});

watch(
  () => selectedAnswer,
  () => {
    props.onUpdateAnswer(
      Array.isArray(selectedAnswer.value)
        ? selectedAnswer.value.map((item) => {
            return {
              selection: item,
              answer: '',
            };
          })
        : [
            {
              selection: selectedAnswer.value,
              answer: '',
            },
          ],
    );
  },
  { deep: true, immediate: true },
);
</script>

<template>
  <div class="question-container">
    <SurveyAreaTitle :title="question.title + `${question.isMultipleAnswer ? ` (${answerCountText}개)` : ''}`">
      <p v-if="question.isRequired" class="required">필수</p>
      <p v-else>선택</p>
    </SurveyAreaTitle>

    <div class="option-container">
      <template v-for="item of question.selections" :key="item.id">
        <div v-if="question.isMultipleAnswer" class="item-row">
          <Checkbox v-model="selectedAnswer" :disabled="!editable" class="option" :value="item" />
          <p>{{ item.contents }}</p>
        </div>
        <div v-else class="item-row">
          <RadioButton v-model="selectedAnswer" :disabled="!editable" :value="item" class="option" />
          <p>{{ item.contents }}</p>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped>
.option-container {
  margin-top: 8px;
  p {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #1c2a4b;
  }
}
.item-row {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
