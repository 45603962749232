<script setup lang="ts">
import InfoFilled from '~~/public/icon/InfoFilled.svg';

import { type MyPageSurvey, myPageService, useMyPageStore } from '~/services/mypage';
import { SurveyModal } from '~/services/payment/component/SurveyModal';
import { type UserSurveyDTO } from '~/services/payment/type';
import { useSurveyStore } from '~/services/survey';

import { type PaginationOptions } from '~/types/global';

import StudySurveyItem from './StudySurveyItem.vue';

const surveyDlg = ref(false);
const surveyData = ref({} as UserSurveyDTO);
const studySeq = ref();
const modalMode = ref('read' as 'read' | 'update' | 'add');

const params = reactive<PaginationOptions>({
  pageNo: 1,
  pageSize: 6,
  orderColumn: '',
  orderType: '',
});

const surveyStore = useSurveyStore();
const store = useMyPageStore();

const surveyItems = computed(() => {
  return store.myPageSurveys.items.filter((item) => {
    return !['C', 'W'].includes(item.refundStatusCd);
  });
});

const onInfinite = () => {
  if (store.myPageSurveys.paging?.hasMore) {
    params.pageNo++;
    store.fetchMyPageSurvey(params, true);
  }
};

const handleGuide = () => {
  $alert(`
    설문 기한 내에 제출하지 않으시면<br />
    조가 <b>지역 랜덤으로 배정</b>됩니다.<br />
    기한 종료 후 내용 변경이 불가능하니,<br />
    기한 내에 작성을 완료해 주세요.
  `);
};

const handleItemCTA = async (type: 'add' | 'update' | 'read', item: MyPageSurvey) => {
  const {
    data: {
      data: { items: _surveyData },
    },
  } = await myPageService.getMyPageSurveyItem(item.surveySeq, item.studySeq);
  console.log('!!', _surveyData);
  surveyStore.setSurveyStudySeq(item.studySeq);
  surveyStore.setSurveyPopupData(_surveyData);
  surveyStore.setSurveyPopupVisible(true);
  surveyStore.setSurveyMode(type);
};

function handleRefetchSurveys() {
  console.log('refetch surveys');
  store.fetchMyPageSurvey(params, false);
}

onBeforeMount(() => {
  store.fetchMyPageSurvey(params, false);
});
</script>

<template>
  <div class="root">
    <div class="header">
      <span class="guide-icon" @click="handleGuide"><img :src="InfoFilled" />조편성 안내</span>
    </div>
    <div class="survey-container">
      <div v-if="store.myPageSurveys.items.length === 0" class="empty">조편성 내역이 없습니다.</div>
      <div v-else class="list">
        <StudySurveyItem
          v-for="item in surveyItems"
          :key="item.studySeq"
          :item="item"
          @read="handleItemCTA('read', item)"
          @add="handleItemCTA('add', item)"
          @update="handleItemCTA('update', item)" />
      </div>
    </div>
    <Observer v-if="store.myPageSurveys.paging?.hasMore" @intersect="onInfinite" />
    <SurveyModal @refetchSurveys="handleRefetchSurveys" />
  </div>
</template>
<style scoped lang="scss">
.header {
  margin: 16px 0;
  display: flex;
  justify-content: flex-end;
  .guide-icon {
    padding: 12px 0;
    color: #666;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    img {
      margin-right: 8px;
    }
  }
}
.survey-container {
  .empty {
    padding: 160px 0 25px;
    font-weight: 400;
    text-align: center;
    color: #aaaaaa;
  }
  .list {
    display: flex;
    flex-direction: column;
  }
}
</style>
