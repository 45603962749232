<script setup lang="ts">
import {
  type SurveyQuestionType,
  SurveyQuestionTypeEnum,
  type UserSurveyQuestionAnswerResponseDTO,
  type UserSurveyQuestionDTO,
  type UserSurveyQuestionSelectionDTO,
} from '../../type';
import SurveyAreaTitle from './components/SurveyAreaTitle.vue';

const props = defineProps<{
  type: SurveyQuestionType;
  question: UserSurveyQuestionDTO;
  editable: boolean;
  onUpdateAnswer: (value: UserSurveyQuestionAnswerResponseDTO[]) => void;
}>();

const prefilledAnswer = computed(() => {
  return props.question.answers.length > 0 ? props.question.answers[0] : ({} as UserSurveyQuestionAnswerResponseDTO);
});

const selectedAnswer = ref<UserSurveyQuestionSelectionDTO>(prefilledAnswer.value.selection);
const reason = ref<string>(prefilledAnswer.value.answer || '');

watch(
  () => selectedAnswer,
  () => {
    reason.value = '';
    props.onUpdateAnswer([{ selection: selectedAnswer.value as UserSurveyQuestionSelectionDTO, answer: reason.value }]);
  },
  { deep: true },
);

watch(
  () => reason,
  () => {
    props.onUpdateAnswer([{ selection: selectedAnswer.value as UserSurveyQuestionSelectionDTO, answer: reason.value }]);
  },
  { deep: true },
);
</script>

<template>
  <div class="question-container">
    <SurveyAreaTitle :title="question.title">
      <p v-if="props.question.isRequired" class="required">필수</p>
      <p v-else>선택</p>
    </SurveyAreaTitle>

    <div class="option-container">
      <template v-for="(item, index) of props.question.selections" :key="item.id">
        <div class="item-row">
          <RadioButton v-model="selectedAnswer" :disabled="!props.editable" :value="item" class="option" />
          <p for="addLeaderYes">{{ item.contents }}</p>
        </div>
        <InputText
          v-if="props.type !== SurveyQuestionTypeEnum.SELECT_SEX && index === 0 && selectedAnswer?.id == item.id"
          v-model="reason"
          type="text"
          :name="`motive-reason-${props.question.id}`"
          placeholder="지원동기 및 나눌 수 있는 점을 작성해주세요. (필수)"
          class="p-inputtext-md p-inputtext-outlined mb-3" />
      </template>
    </div>
  </div>
</template>

<style scoped>
.option-container {
  margin-top: 8px;
  p {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #1c2a4b;
  }
}
.item-row {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
