<script setup lang="ts">
import { now, safeDateParse } from '~/libs/dateUtils';

import { type MyPageSurvey } from '../../type';
import StudyTag from './StudyTag.vue';

const props = defineProps<{
  item: MyPageSurvey;
}>();

defineEmits<{
  (e: 'add', item: MyPageSurvey): void;
  (e: 'update', item: MyPageSurvey): void;
  (e: 'read', item: MyPageSurvey): void;
}>();

const item = computed(() => props.item);
const expiration = computed(() => {
  return safeDateParse(item.value.surveyEndAt).format('YY.MM.DD HH:mm');
});

const dDay = computed(() => {
  const today = now().format('YYYY-MM-DD');
  const isExpired = now().unix() - safeDateParse(item.value.surveyEndAt).unix() > 0;
  const end = safeDateParse(item.value.surveyEndAt);
  const result = end.diff(today, 'day');

  return isExpired ? -1 : result;
});
</script>

<template>
  <div class="item">
    <div class="thumb">
      <img :src="item.displayThumbnailUrl" />
    </div>
    <div class="content">
      <div class="tag-container"><StudyTag :survey="item" /></div>
      <div class="title">{{ item.displayName }}</div>
      <div v-show="item.creatorNames && item.creatorNames.length > 0" class="creator">
        {{ item.creatorNames?.join(', ') }}
      </div>
      <div class="submit-info">
        <div v-if="!item.surveySubmitted && !item.surveyClosed" class="before">
          <label>제출전</label>
          <span v-if="dDay >= 0" class="d-day">D-{{ dDay }}</span>
          <span v-if="dDay >= 0" class="expiration">{{ expiration }}</span>
        </div>
        <div v-if="item.surveySubmitted" class="complete">
          <label>제출완료</label>
          <span v-if="dDay >= 0" class="d-day">D-{{ dDay }}</span>
          <span v-if="dDay >= 0" class="expiration">{{ expiration }}</span>
        </div>
        <div v-if="!item.surveySubmitted && item.surveyClosed" class="not">
          <label>미제출, 지역랜덤</label>
          <span class="expiration">{{ expiration }}</span>
        </div>
      </div>
    </div>
    <div v-if="item.surveySeq" class="action">
      <Button
        v-if="!item.surveySubmitted"
        label="설문 제출"
        severity="secondary"
        outlined
        :disabled="item.surveyClosed || item.studyBreakYn === 'Y'"
        @click="$emit('add')" />
      <Button
        v-if="item.surveySubmitted && !item.surveyClosed"
        label="설문 수정"
        severity="secondary"
        outlined
        @click="$emit('update')" />
      <Button
        v-if="item.surveySubmitted && item.surveyClosed"
        label="설문 보기"
        severity="secondary"
        outlined
        @click="$emit('read')" />
    </div>
  </div>
</template>
<style scoped lang="scss">
.item {
  display: flex;
  flex-direction: row;
  padding: 32px 0;
  border-bottom: 1px solid #f2f2f2;
  align-items: center;
  &:first-of-type {
    padding-top: 0;
  }
  @media screen and (max-width: $md) {
    flex-wrap: wrap;
    border-bottom: none;
    padding: 20px 0;
  }
  .thumb {
    margin-right: 24px;
    display: flex;
    @media screen and (max-width: $md) {
      margin-right: 16px;
      align-self: flex-start;
    }
    img {
      width: 157px;
      height: 88px;
      border-radius: 8px;
      object-fit: cover;
      @media screen and (max-width: $md) {
        width: 114px;
        height: 64px;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: $md) {
      flex-grow: 1;
      flex-basis: 50%;
    }
    .tag-container {
      font-size: 0;
      margin-bottom: 4px;
    }
    .title {
      text-overflow: ellipsis;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 4px;
      @media screen and (max-width: $md) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 195px;
      }
    }
    .creator {
      color: #888;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 8px;
    }
    .submit-info {
      > div {
        line-height: 16px;
        label {
          &::after {
            content: 'l';
            margin: 0 4px;
            color: #eeeeee;
            font-weight: 400;
            font-size: 14px;
          }
        }
        .d-day {
          color: #e4515a;
          margin-right: 4px;
          font-weight: 600;
        }
        .expiration {
          color: #444;
        }
      }
      .complete {
        label {
          color: #0a84ff;
          font-weight: 600;
        }
      }
    }
  }

  .action {
    margin-left: auto;
    @media screen and (max-width: $md) {
      flex-grow: 1;
      margin-top: 16px;
    }
    button {
      width: 100px;
      height: 42px;
      font-size: 14px;
      font-weight: 700;
      @media screen and (max-width: $md) {
        width: 100%;
        height: 40px;
      }
    }
  }
}
</style>
