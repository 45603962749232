<script setup lang="ts">
import { type UserSurveyQuestionDTO } from '../../type';
import SurveyAreaTitle from './components/SurveyAreaTitle.vue';

const props = defineProps<{
  question: UserSurveyQuestionDTO;
  editable: boolean;
  onUpdateAnswer: (value: string) => void;
}>();
const prefilledAnswer = computed(() => {
  return props.question.answers.length > 0 ? props.question.answers[0].answer : '';
});

const selectedAnswer = ref<string>(prefilledAnswer.value + '');

watch(
  () => selectedAnswer,
  () => {
    props.onUpdateAnswer(selectedAnswer.value);
  },
  { immediate: true, deep: true },
);
</script>
<template>
  <div class="question-container">
    <SurveyAreaTitle :title="props.question.title">
      <p v-if="props.question.isRequired" class="required">필수</p>
      <p v-else>선택</p>
    </SurveyAreaTitle>

    <div class="option-container">
      <InputText
        v-model="selectedAnswer"
        :disabled="!props.editable"
        type="text"
        placeholder="답변을 입력하세요"
        class="p-inputtext-md p-inputtext-outlined" />
    </div>
  </div>
</template>

<style scoped>
.option-container {
  margin-top: 8px;
  p {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #1c2a4b;
  }
}
.item-row {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
