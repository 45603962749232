<script setup lang="ts">
import Tracker from '~/libs/Tracker';
import { safeDateParse } from '~/libs/dateUtils';

import Utility from '~/services/Utility';
import { type MyPageStudyRoomClass } from '~/services/mypage';

const dateFormat = $dateFormat;
const route = useRoute();
const router = useRouter();

const props = defineProps<{
  studyClass: MyPageStudyRoomClass;
}>();

const classStatusCd = computed<string>(() => {
  let result = '';
  if (props.studyClass.studyRefundStatusCd === 'C') {
    result = 'REFUND_COMPLETE';
  } else if (props.studyClass.studyRefundStatusCd === 'W') {
    result = 'REFUND_WAIT';
  } else if (props.studyClass.studyBreakYn === 'Y') {
    result = 'STUDY_BREAK_ING';
  } else if (props.studyClass.studyStatusCd === 'E') {
    result = 'STUDY_END';
  } else if (props.studyClass.studyStatusCd === 'I') {
    result = 'STUDY_ING';
  } else if (props.studyClass.studyStatusCd === 'W') {
    result = 'STUDY_WAIT';
  }
  return result;
});

const classStatusNm = computed<string>(() => {
  let result = '';
  if (props.studyClass.studyRefundStatusCd === 'C') {
    result = '환불완료';
  } else if (props.studyClass.studyRefundStatusCd === 'W') {
    result = '환불대기';
  } else if (props.studyClass.studyBreakYn === 'Y') {
    result = '휴강중';
  } else if (props.studyClass.studyStatusCd === 'E') {
    result = '수강종료';
  } else if (props.studyClass.studyStatusCd === 'I') {
    result = '수강중';
  } else if (props.studyClass.studyStatusCd === 'W') {
    result = '수강대기';
  }
  return result;
});

const classStatusStyle = computed(() => {
  let result;
  if (props.studyClass.studyRefundStatusCd === 'C') {
    result = { background: '#e4515a', color: '#ffffff' };
  } else if (props.studyClass.studyRefundStatusCd === 'W') {
    result = { background: '#e4515a', color: '#ffffff' };
  } else if (props.studyClass.studyBreakYn === 'Y') {
    result = { background: '#f2f2f2', color: '#888' };
  } else if (props.studyClass.studyStatusCd === 'E') {
    result = { background: '#aaaaaa', color: '#ffffff' };
  } else if (props.studyClass.studyStatusCd === 'I') {
    result = { background: '#000', color: '#ffffff' };
  } else if (props.studyClass.studyStatusCd === 'W') {
    result = { background: '#aaaaaa', color: '#ffffff' };
  }
  return result;
});

const studyDateString = computed(() => {
  const { isLiveStudy, liveStudyStartAt, liveStudyEndAt, studyStartDate, studyEndDate } = props.studyClass;

  if (isLiveStudy && liveStudyStartAt && liveStudyEndAt) {
    // 라이브 특성상, 같은 날짜로 간주
    return `${safeDateParse(liveStudyStartAt).format('YYYY.MM.DD HH:mm')} - ${safeDateParse(liveStudyEndAt).format('HH:mm')}`;
  }

  const DATE_FORMAT = 'YYYY.MM.DD';
  return `${safeDateParse(studyStartDate).format(DATE_FORMAT)} - ${safeDateParse(studyEndDate).format(DATE_FORMAT)}`;
});

const goDetail = async () => {
  if (classStatusCd.value === 'REFUND_WAIT') {
    const confirmText = '강의를 다시 수강하시겠습니까? <br/>구매내역에서 환불요청을 취소할 수 있습니다.';
    if ((await $confirm(confirmText)) === 'accept') {
      router.push({ path: '/mypage/payment' });
    }
    return;
  }
  // else if (classStatusCd.value === 'STUDY_END') {
  //   await $alert('현재 수강종료 상태로 클래스를 수강할 수 없습니다.');
  //   return;
  // }
  /* else if(classStatusCd.value === 'STUDY_WAIT'){
    await $alert('현재 수강대기 상태로 클래스를 수강할 수 없습니다.');
    return;
  } */
  Tracker['Click Purchased Product'](props.studyClass);
  router.push({
    path: `/mypage/studyroom/lecture`,
    query: { studySeq: props.studyClass.studySeq, displaySeq: props.studyClass.displaySeq },
  });
};
</script>
<template>
  <NuxtLink class="wb-mypage-lecture-item" style="cursor: pointer" @click="goDetail">
    <div class="wb-mypage-lecture-item__thumb">
      <Image :src="Utility.getOptimized(props.studyClass.displayThumbnailUrl, 'PRODUCT_THUMBNAIL')" alt="" />
    </div>
    <div class="wb-mypage-lecture-item__title">
      <Tag :value="classStatusNm" :style="classStatusStyle"></Tag>
      <p>{{ props.studyClass.displayName }}</p>
    </div>
    <div class="wb-mypage-lecture-item__date">
      <div>
        {{ studyDateString }}
      </div>
      <!-- <span v-if="['STUDY_ING', 'STUDY_BREAK_ING'].includes(classStatusCd)"
        >(<span class="m-0 badge">{{ props.studyClass.studyPossibleDay }}일</span> 남음)</span
      > -->
    </div>

    <div class="wb-mypage-lecture-item__info">
      <tempalte v-if="props.studyClass.studyStatusCd === 'I'">
        <strong :style="props.studyClass.studyStatusCd === 'I' ? {} : { color: '#aaa' }"
          >{{ Math.round(props.studyClass.progressPercent) }}%</strong
        >
        <ProgressBar :value="props.studyClass.progressPercent"></ProgressBar>
        <!-- NOTE: https://weolbu-company.atlassian.net/browse/FIRST-59?atlOrigin=eyJpIjoiYTRjZDdkM2JkMTQ5NDg3YTgzZTFmYzQzNDgzNDc0ZTciLCJwIjoiaiJ9 -->
        <!-- <p>남은일수 {{ props.studyClass.studyPossibleDay }} 일</p> -->
      </tempalte>
      <tempalte v-else-if="props.studyClass.studyStatusCd === 'W'"> </tempalte>
      <template v-else>
        <div class="wb-mypage-lecture-item__info-text">
          수강률 <strong>{{ Math.round(props.studyClass.progressPercent) }}%</strong>
        </div>
      </template>
    </div>
  </NuxtLink>
</template>
