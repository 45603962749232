<script setup lang="ts">
import Utility from '~/services/Utility';
import { useCommonStore } from '~/services/common';
import { type GetStudyReportClassOptions, type StudyReport, useMyPageStore } from '~/services/mypage';

const INIT_PARAMS = (): GetStudyReportClassOptions => ({
  orderColumn: '',
  orderType: '',
  pageNo: 1,
  pageSize: 5,
  studyStatusCd: '',
});
const router = useRouter();
const store = useMyPageStore();
const commonStore = useCommonStore();
const params = reactive<GetStudyReportClassOptions>(INIT_PARAMS());
// const setReportInfo = inject("setReportInfo");
const dropOptions = reactive([
  { value: '', label: '전체' },
  { value: 'W', label: '대기' },
  { value: 'I', label: '진행' },
  { value: 'E', label: '종료' },
]);

const fireFetchList = (append = false) => {
  if (store.myStudyReports.paging) {
    store.myStudyReports.paging.hasMore = false;
  }
  store.fetchMyStudyReports(params, append);
};

const onInfinite = () => {
  if (store.myStudyReports.paging?.hasMore) {
    params.pageNo++;
    fireFetchList(true);
  }
};

watch(
  () => params.studyStatusCd,
  () => {
    params.pageNo = 1;
    fireFetchList();
  },
);

const openDetail = ({ displaySeq, studySeq, displayName }: StudyReport) => {
  router.push({
    path: '/mypage/studyroom/report',
    query: {
      displaySeq,
      studySeq,
      displayName: encodeURIComponent(displayName),
    },
  });
  // if (typeof setReportInfo === "function") {
  // 	setReportInfo(item);
  // }
};

fireFetchList();
</script>

<template>
  <div class="wb-mypage-lecture">
    <div class="wb-mypage-lecture__head">
      <Dropdown
        v-model="params.studyStatusCd"
        class="p-dropdown-sm"
        :options="commonStore.getStudyStatusCodesWithAll"
        option-label="cdNm"
        option-value="cdValue"
        placeholder="전체" />
    </div>
    <div v-if="store.myStudyReports.items.length === 0" class="wb-mypage-none">
      <div class="wb-mypage-none__text">
        <p>과제가 없습니다.</p>
      </div>
    </div>
    <div v-else class="wb-mypage-lecture-list">
      <NuxtLink
        v-for="item in store.myStudyReports.items"
        class="wb-mypage-lecture-item"
        style="cursor: pointer"
        @click="openDetail(item)">
        <div class="wb-mypage-lecture-item__thumb">
          <Image :src="Utility.getOptimized(item.displayThumbnailUrl, 'PRODUCT_THUMBNAIL')" alt="" />
        </div>
        <div class="wb-mypage-lecture-item__title">
          <p>{{ item.displayName }}</p>
        </div>
        <div class="wb-mypage-lecture-item__button">
          <Button label="과제관리" size="small" severity="secondary" outlined />
        </div>
      </NuxtLink>
    </div>
    <Observer v-if="store.myStudyReports.paging?.hasMore" @intersect="onInfinite" />
  </div>
</template>

<style scoped></style>
