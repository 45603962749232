<script setup lang="ts">
import { MYPAGE_STUDYROOM_ORDER_COLUMN_KEY } from '~/constants/browser-storage-keys';

import { safeLocalStorage } from '~/libs/safeLocalStorage';

import { useCommonStore } from '~/services/common';
import { type MyPageStudyRoomClass, type MyPageStudyRoomClassOptions, useMyPageStore } from '~/services/mypage';
import { myPageService } from '~/services/mypage';
import StudyClassItem from '~/services/mypage/component/studyroom/StudyClass.vue';
import { useUserAuthStore } from '~/services/userAuth';

import { type Pagination } from '~/types/global';

const commonStore = useCommonStore();
const userAuthStore = useUserAuthStore();
const initOrderColumn: string = safeLocalStorage.getItem(MYPAGE_STUDYROOM_ORDER_COLUMN_KEY) || '';

const INIT_FILTER_PARAMS = (): MyPageStudyRoomClassOptions => ({
  pageNo: 1,
  pageSize: 6,
  orderColumn: initOrderColumn,
  orderType: 'DESC',
  searchFilterType: '',
});

const filterParams = reactive<MyPageStudyRoomClassOptions>(INIT_FILTER_PARAMS());
const studyRoomClasses = reactive<{
  items: MyPageStudyRoomClass[];
  paging?: Pagination;
}>({
  items: [],
});

const searchFilterList = reactive([
  { value: '', label: '전체' },
  { value: 'STUDY_WAIT', label: '수강대기' },
  { value: 'STUDY_ING', label: '수강중' },
  { value: 'STUDY_END', label: '수강종료' },
  { value: 'STUDY_BREAK_ING', label: '휴강중' },
  { value: 'STUDY_REFUND_WAIT', label: '환불대기' },
  { value: 'STUDY_REFUND_COMPLETE', label: '환불완료' },
]);

const { pending: isPending } = await useAsyncData(
  'study-room-classes',
  async () => {
    const res = await myPageService.getMyPageStudyRoomClasses(filterParams);
    if (filterParams.pageNo === 1) {
      studyRoomClasses.items = res.data?.items || [];
    } else {
      studyRoomClasses.items = studyRoomClasses.items.concat(res.data?.items || []);
    }
    studyRoomClasses.paging = res.data?.paging;
    return res.data;
  },
  {
    watch: [filterParams, () => userAuthStore.user],
    deep: true,
  },
);

const onInfinite = async () => {
  if (studyRoomClasses.paging?.hasMore) {
    filterParams.pageNo++;
  }
};
</script>

<template>
  <div class="wb-mypage-lecture">
    <div class="wb-mypage-lecture__head flex gap-2">
      <Dropdown
        v-model="filterParams.orderColumn"
        :options="[
          { label: '등록 순', value: '' },
          { label: '최근 본 순', value: 'STUDY_LAST_DATE' },
        ]"
        option-label="label"
        option-value="value"
        placeholder="등록 순"
        class="p-dropdown-sm"
        @update:model-value="safeLocalStorage.setItem(MYPAGE_STUDYROOM_ORDER_COLUMN_KEY, $event)" />
      <Dropdown
        v-model="filterParams.searchFilterType"
        :options="commonStore.getStudyStatusCodesWithAll"
        option-label="cdNm"
        option-value="cdValue"
        placeholder="전체"
        class="p-dropdown-sm" />
    </div>

    <div v-if="studyRoomClasses.items?.length === 0 && !isPending" class="wb-mypage-none">
      <div class="wb-mypage-none__text">
        <p>클래스가 없습니다.</p>
      </div>
    </div>
    <div v-else class="wb-mypage-lecture-list">
      <StudyClassItem
        v-for="(item, index) in studyRoomClasses.items"
        :key="`study-class_${index}`"
        :study-class="item" />
    </div>
    <div
      v-if="isPending"
      class="flex flex-col items-center"
      :class="studyRoomClasses.items?.length === 0 ? 'py-40' : 'py-20'">
      <BlueSpinner />
    </div>
    <Observer v-if="studyRoomClasses.paging?.hasMore" @intersect="onInfinite" />
  </div>
</template>

<style scoped></style>
