<script setup lang="ts">
import { type StudyCertificate, myPageService } from '~/services/mypage';
import Utility from '~/services/Utility';
import sign from './../../../../../public/icon/sign.svg';

interface State extends StudyCertificate {
  studyStartDateLabel: string;
  studyEndDateLabel: string;
}

const props = defineProps<{ visible: boolean; item: StudyCertificate }>();
const emit = defineEmits<{ (e: 'update:visible', visible: boolean): void }>();

const handleVisible = computed<boolean>({
  get() {
    return props.visible;
  },
  set(v) {
    emit('update:visible', v);
  },
});
const { data } = await useAsyncData(async () => {
  const res = await myPageService.getCertificateInfo(props.item.studySeq, props.item.displaySeq);
  const items = res.data.data.items;
  return {
    ...items,
    priceLabel: Utility.setCommaPer3Digit(items.paymentPrice),
  };
});
</script>
<script lang="ts">
import html2pdf from 'html2pdf.js';

export default {
  data() {
    return {
      print: false,
    };
  },
  methods: {
    exportToPDF() {
      this.print = true;
      html2pdf(this.$refs.pdfarea, {
        margin: [0, 20],
        filename: 'certificates.pdf',
        image: { type: 'jpg', quality: 1 },
        //	allowTaint 옵션추가
        html2canvas: {
          useCORS: true,
          scrollY: 0,
          scale: 1,
          dpi: 300,
          letterRendering: true,
          allowTaint: false, //useCORS를 true로 설정 시 반드시 allowTaint를 false처리 해주어야함
        },
        jsPDF: {
          orientation: 'portrait',
          unit: 'mm',
          format: 'a4',
          compressPDF: true,
        },
      });
      setTimeout(() => {
        this.print = false;
      }, 100);
    },
  },
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://printjs-4de6.kxcdn.com/print.min.js';
    document.head.appendChild(script);
  },
};
</script>

<template>
  <Dialog v-model:visible="handleVisible" modal class="wb-dialog">
    <div class="wb-dialog__close show">
      <Button icon="pi pi-times" text rounded @click="handleVisible = false" />
    </div>
    <div ref="pdfarea" id="printArea">
      <div class="wb-dialog-certificates-print" :class="print ? 'active' : 'noneactive'">
        <div class="wb-dialog__head">
          <Button label="수강 확인증" severity="secondary" link icon="pi pi-times" @click="handleVisible = false" />
        </div>
        <div class="wb-dialog__title">수강 확인증</div>
        <div class="wb-dialog__contents">
          <div class="wb-dialog-certificates">
            <table>
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 80%" />
              </colgroup>
              <tbody>
                <tr>
                  <th>이름</th>
                  <td>{{ data?.userName }}</td>
                </tr>
                <tr>
                  <th>과정명</th>
                  <td>{{ data?.displayName }}</td>
                </tr>
                <tr>
                  <th>수강기간</th>
                  <td>{{ data?.studyStartDate }} - {{ data?.studyEndDate }}</td>
                </tr>
                <tr>
                  <th>수강금액</th>
                  <td>{{ data?.priceLabel }}원</td>
                </tr>
              </tbody>
            </table>
            <div class="wb-dialog-certificates__text">
              교육과정을 성실히 이수하였기에 수료증을 수여합니다.<br />
              {{ data?.printDate }}
            </div>
            <div class="wb-dialog-certificates__sign">
              <p>월급쟁이 부자들</p>
              <img :src="sign" alt="" />
            </div>
            <div class="wb-dialog-certificates__foot">
              사무실 서울시 서초구 사임당로 143, 크로스143타워 2층<br />
              교육장 서울시 강남구 역삼로 134, MS빌딩 2층 <br />
              사업자등록번호 321-86-00842 | 통신판매업신고번호 제2018-서울강남-03590호
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wb-dialog__button">
      <Button label="다운로드" severity="secondary" outlined @click="exportToPDF" />
      <Button
        label="인쇄하기"
        onclick="printJS({
					printable:'printArea',
					type:'html',
					css: ['/print.css'],
					scanStyles : false
				})" />
    </div>
  </Dialog>
</template>
