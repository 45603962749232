export interface PrePaymentOptions {
  displaySeq: number;
  pg: string;
  payMethod: string;
  usePoint: number;
  userCouponSeq: null | number; // If `userCouponSeq` can be other than `null`, replace `number` with the respective type.
  optionSeq: number;
  earlyPayment: string;
  captchaId?: string;
  captchaValue?: string;
  urlQuery: any;
}
export interface PrePaymentData {
  merchantUid: string;
  totalPrice: number;
  paymentPrice: number;
  couponPrice: number;
  usePoint: number;
}
export interface FreePaymentOptions {
  merchantUid: string;
}
export interface FreePaymentData {
  resultType?: 'GearCommonResultCodes';
}
export interface PaymentInfo {
  displaySeq: string | number;
  optionSeq?: number | null;
  from?: string | null; // 모바일결제인지 아닌지.
  imp_success?: boolean; // import 모바일 결제 성공/실패
  error_msg?: string;
  orderNo?: string | null; // 아임포트 mobile 용 결제 주문번호.
  referralCode?: string | null; // 추천인 코드
}
export interface PaymentWebhook {
  imp_uid: string;
  merchant_uid: string;
}
export interface IMportPaymentResult {
  success: boolean;
  error_msg: string | null;
  imp_uid: string;
  pay_method: string;
  merchant_uid: string;
  name: string;
  paid_amount: number;
  currency: string;
  pg_provider: string;
  pg_type: string;
  pg_tid: string;
  apply_num: string;
  buyer_name: string;
  buyer_email: string;
  buyer_tel: string;
  buyer_addr: string;
  buyer_postcode: string;
  custom_data: any; // Replace `any` with the actual type if known.
  status: string;
  paid_at: number;
  receipt_url: string;
  card_name: string;
  bank_name: string;
  card_quota: number;
  card_number: string;
  pgValue?: string;
  pgName?: string;
  isMobile?: boolean;
  paymentSeq?: number;
}

export interface UserSurveyAnswerDetail {
  surveyDetailSeq?: number | null;
  surveyType?: string;
  surveyValue: number | string | null;
  surveySeq?: number;
  surveyDetailName?: string;
  surveyAnswerSeq?: number;
}

export interface SurveyAnswer {
  displaySeq: number;
  studySeq: number;
  surveyAnswerSeq: number;
  surveyDetailSeq: number;
  surveySeq: number;
  surveyType: 'AREA' | 'JOB' | 'POS' | string;
  surveyValue: string | null;
}

export type SurveyQuestionType = 'STRING' | 'SELECT' | 'SELECT_LEADER' | 'SELECT_MANAGER' | 'SELECT_SEX';

export enum SurveyQuestionTypeEnum {
  STRING = 'STRING',
  SELECT = 'SELECT',
  SELECT_LEADER = 'SELECT_LEADER',
  SELECT_MANAGER = 'SELECT_MANAGER',
  SELECT_SEX = 'SELECT_SEX',
}

export enum SurveyQuestionTypeNameEnum {
  STRING = '주관식',
  SELECT = '객관식',
  SELECT_LEADER = '조장지원 객관식',
  SELECT_MANAGER = '운영진지원 객관식',
  SELECT_SEX = '성별 객관식',
}

export interface UserSurveyQuestionSelectionDTO {
  id: number;
  contents: string;
  position: number;
}

export interface UserSurveyQuestionAnswerResponseDTO {
  selection: UserSurveyQuestionSelectionDTO;
  answer: string | null; // 주관식 답변 내용
}

export interface UserSurveyQuestionDTO {
  id: number;
  title: string;
  type: SurveyQuestionType;
  isRequired: boolean;
  minAnswerCount: number;
  maxAnswerCount: number;
  isMultipleAnswer: boolean;
  position: number;
  selections: UserSurveyQuestionSelectionDTO[];
  answers: UserSurveyQuestionAnswerResponseDTO[];
}

export interface UserSurveyDTO {
  id: number;
  displayName: string;
  displayOptionName: string;
  title: string;
  guideContents: string;
  agreeContents: string;
  expiredAt: string;
  isUsedAgreeContents: boolean;
  questions: UserSurveyQuestionDTO[];
}

export interface UserSurveyQuestionAnswerForm {
  surveyQuestionId: number;
  selectionIds: number[];
  answer?: string | null;
}

export interface UserSurveyForm {
  userStudyId: number;
  answers: UserSurveyQuestionAnswerForm[];
}
