<script setup lang="ts">
import { AxiosError } from 'axios';
import dompurify from 'dompurify';

import { useSurveyStore } from '~/services/survey';

import { usePaymentStore } from '../../store';
import {
  SurveyQuestionTypeEnum,
  type UserSurveyForm,
  type UserSurveyQuestionDTO,
  type UserSurveyQuestionSelectionDTO,
} from '../../type';
import SurveyMultipleChoiceQuestion from './SurveyMultipleChoiceQuestion.vue';
import SurveyShortAnswerQuestion from './SurveyShortAnswerQuestion.vue';
import SurveySpecialQuestion from './SurveySpecialQuestion.vue';

const props = defineProps<{
  displaySeq?: string;
}>();

const emit = defineEmits<{
  (e: 'refetchSurveys'): void;
}>();

const isGroupYnChecked = ref(false);
const isValidate = ref(false);
const isEmpty = $isEmpty;

const surveyStore = useSurveyStore();
const paymentStore = usePaymentStore();

const surveyData = computed(() => surveyStore.getSurveyPopupData);
const studySeq = computed(() => surveyStore.surveyStudySeq);
const surveyMode = computed(() => surveyStore.surveyMode);

watch(
  () => surveyMode.value,
  () => {
    isGroupYnChecked.value = surveyMode.value !== 'add';
  },
  { deep: true, immediate: true },
);

const handleVisible = computed<boolean>({
  get: () => {
    return surveyStore.surveyPopupVisible;
  },
  set: (val) => {
    console.log('val :>> ', val);
    surveyStore.setSurveyPopupVisible(val);
  },
});

const convertToSurveyPayload = (): UserSurveyForm => {
  const response = {
    userStudyId: studySeq.value,
    answers: [],
  } as UserSurveyForm;

  surveyData.value?.questions.forEach((question) => {
    const selectionIds = question.answers
      .filter((answer) => answer.selection && answer.selection.id)
      .map((answer) => answer.selection?.id) as number[];

    if (!question.answers[0]?.answer && selectionIds.length === 0) {
      // 설문 응답이 없는 경우 데이터를 아예 전송하지 않음
      return;
    }

    response.answers.push({
      surveyQuestionId: question.id,
      selectionIds,
      answer: question.answers[0]?.answer,
    });
  });

  return response;
};

async function onSaveSurvey() {
  if (!(await validationCheck(false))) {
    console.error('validationCheck 에서 early return');
    return;
  }

  if (await inValidateSpecialQuestionReasonCheck(false)) {
    console.error('inValidateSpecialQuestionReasonCheck 에서 early return');
    return;
  }

  if (!surveyData.value) {
    console.error('surveyData.value 가 없습니다.');
    return;
  }

  try {
    const surveyResults: UserSurveyForm = convertToSurveyPayload();
    if (surveyMode.value === 'update') {
      await paymentStore.updateSurvey(surveyData.value.id, surveyResults);
      $alert(`조편성 설문이 수정되었습니다.`);
    } else if (surveyMode.value === 'add') {
      if (props.displaySeq) {
        // Payment.vue에서 조편성을 바로 제출할때
        await paymentStore.addSurveyLegacy(props.displaySeq, surveyResults);
      } else if (studySeq.value) {
        const response = await paymentStore.addSurvey(surveyData.value.id, surveyResults);
        if (response.data.status?.errorCode) {
          return;
        }
      }
      $alert(
        `조편성 설문이 제출되었습니다.<br /><u>마이페이지 > 내강의실 > 조편성</u>에서<br /> 수정하실 수 있습니다.`,
      );
    }
    surveyStore.setSurveyPopupVisible(false);
    emit('refetchSurveys');
  } catch (err) {
    if (err instanceof AxiosError && err.response?.data?.status?.errorMessage) {
      console.error('err?.response?.data?.status :>> ', err?.response?.data?.status);
      await $alert(err.response.data.status.errorMessage);
    }
    console.error('err :>> ', err);
  }
}

const handleClose = async () => {
  let shouldClose: any = false;
  if (surveyMode.value === 'add') {
    shouldClose = await $customConfirm({
      message:
        '기간 내에 설문 미제출 시,\n조가 랜덤으로 배정됩니다. 설문을 취소하시겠습니까?\n\n설문은 [마이페이지 > 내 강의실 > 조편성] 에서 다시 확인할 수 있습니다.',
      leftButtonLabel: '작성 취소',
      rightButtonLabel: '설문 작성하기',
    });
  }
  if (shouldClose !== true) {
    handleVisible.value = false;
  }
};

const inValidateSpecialQuestionReasonCheck = async (isSilent?: boolean) => {
  let notValidate = false;
  const elements = document.getElementsByName('motive-reason') as NodeListOf<HTMLInputElement>;

  for (const element of elements) {
    if (element.value.trim() === '') {
      if (!isSilent) {
        await $alert(`지원동기를 입력해주세요.`);
      }
      notValidate = true;
      break;
    }
  }

  return notValidate;
};

const validationCheck = async (isSilent: boolean) => {
  if (!surveyData.value) return;
  // surveyData.questions 의 isRequired가 true인 질문 중에 answer가 없는 질문이 있는지 체크
  let errorQuestion: UserSurveyQuestionDTO | null = null;

  for (const question of surveyData.value.questions) {
    switch (question.type) {
      case SurveyQuestionTypeEnum.SELECT: {
        const hasNoAnswer =
          question.answers.length === 0 || (question.answers.length > 0 && !question.answers[0].selection);
        if (hasNoAnswer && question.isRequired) {
          errorQuestion = question;
        }
        if (hasNoAnswer && !question.isRequired) {
          continue;
        }
        if (
          question.isMultipleAnswer &&
          (question.answers.length < question.minAnswerCount || question.answers.length > question.maxAnswerCount)
        ) {
          errorQuestion = question;
        }
        if (!question.isMultipleAnswer && question.answers.length === 0) {
          errorQuestion = question;
        }
        break;
      }

      case SurveyQuestionTypeEnum.STRING: {
        const hasNoAnswer =
          question.answers.length === 0 ||
          (question.answers.length > 0 && question.answers[0].answer?.trim().length === 0);
        if (hasNoAnswer && question.isRequired) {
          errorQuestion = question;
        }
        break;
      }

      case SurveyQuestionTypeEnum.SELECT_LEADER:
      case SurveyQuestionTypeEnum.SELECT_MANAGER:
      case SurveyQuestionTypeEnum.SELECT_SEX: {
        const hasNoAnswer =
          question.answers.length === 0 || (question.answers.length > 0 && !question.answers[0].selection);
        if (hasNoAnswer && question.isRequired) {
          errorQuestion = question;
          break;
        }
        if (hasNoAnswer && !question.isRequired) {
          continue;
        }

        const elements = document.getElementsByName(
          `motive-reason-${question.id}`,
        ) as NodeListOf<HTMLInputElement> | null;

        for (const element of elements ?? []) {
          if (element.value.trim() === '' && !isSilent) {
            errorQuestion = question;
            break;
          }
        }
        break;
      }
    }
  }

  if (errorQuestion) {
    if (!isSilent) {
      if (errorQuestion.isMultipleAnswer && errorQuestion.answers.length > errorQuestion.maxAnswerCount) {
        await $alert(`최대 선택 개수를 초과 했습니다.<br />질문: <u>${errorQuestion.title}</u>`);
      } else {
        await $alert(`필수 답변이 완료 되지 않았습니다.<br />질문: <u>${errorQuestion.title}</u>`);
      }
    }
    isValidate.value = false;
    return false;
  }

  if (surveyData.value.isUsedAgreeContents && isGroupYnChecked.value === false) {
    if (!isSilent) {
      await $alert(`약관에 동의에 체크 해주세요`);
    }
    isValidate.value = false;
    return false;
  }

  isValidate.value = true;
  return true;
};

watch(
  [surveyData.value, isGroupYnChecked],
  () => {
    validationCheck(true);
  },
  { deep: true },
);
</script>
<template>
  <Dialog :visible="handleVisible" modal class="wb-dialog">
    <div class="wb-dialog__head">
      <Button icon="pi pi-times" :label="surveyData.title" @click="handleClose" />
    </div>
    <div class="wb-dialog__title">{{ surveyData.title }}</div>
    <div class="wb-dialog__contents">
      <div class="wb-order-dialog-add">
        <div class="wb-order-dialog-add__head" style="margin-bottom: 0px">
          <strong>설문 안내</strong>
          <div class="wb-order-survey-desc" v-html="dompurify.sanitize(surveyData.guideContents)"></div>
        </div>
        <div class="p-fluid">
          <template v-for="(item, index) of surveyData.questions" :key="item.id">
            <SurveyMultipleChoiceQuestion
              v-if="item.type === SurveyQuestionTypeEnum.SELECT"
              :question="item"
              :editable="surveyMode !== 'read'"
              :on-update-answer="
                (value) => {
                  surveyData.questions[index].answers = value;
                }
              " />
            <SurveyShortAnswerQuestion
              v-else-if="item.type === SurveyQuestionTypeEnum.STRING"
              :question="item"
              :editable="surveyMode !== 'read'"
              :on-update-answer="
                (value) => {
                  surveyData.questions[index].answers[0] = {
                    selection: {} as UserSurveyQuestionSelectionDTO,
                    answer: value,
                  };
                }
              " />
            <SurveySpecialQuestion
              v-else
              :type="item.type"
              :question="item"
              :editable="surveyMode !== 'read'"
              :on-update-answer="
                (value) => {
                  surveyData.questions[index].answers = value;
                }
              " />
          </template>
          <div class="">
            <ul>
              <li style="margin-top: 40px; margin-bottom: 26px; word-break: keep-all">
                <div
                  v-if="surveyData.agreeContents"
                  style="margin-top: 10px; margin-bottom: 20px"
                  v-html="dompurify.sanitize(surveyData.agreeContents)" />
              </li>
              <li>
                <p v-if="surveyData.isUsedAgreeContents">
                  <Checkbox v-model="isGroupYnChecked" :disabled="surveyMode === 'read'" binary class="option" />
                  <label>위 내용을 확인했으며, 동의합니다</label>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="wb-dialog__button">
      <Button :label="surveyMode === 'read' ? '확인' : '닫기'" severity="secondary" outlined @click="handleClose" />
      <Button
        v-show="surveyMode !== 'read'"
        :label="surveyMode === 'update' ? '수정' : '제출'"
        @click="onSaveSurvey()" />
    </div>
  </Dialog>
</template>
<style lang="scss" scoped>
.wb-order-survey-desc {
  margin: 8px 0 40px;
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;

  & img {
    width: 100%;
  }
}

.wb-order-dialog-add__head {
  margin-top: 16px;
  word-break: keep-all;
  border: 0px solid #dddfe4;
  border-bottom-width: 1px;

  p {
    margin-top: 0px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.option {
  margin-right: 8px;
}

.question-container {
  border: 0px solid #dddfe4;
  border-bottom-width: 1px;
  padding: 40px 0;
}
</style>
